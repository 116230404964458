import React from 'react';
import { Link } from 'gatsby';
import Divider from './Divider.jsx';

import './Footer.scss';

const Footer = props => (
    <footer>
        <div className="container-fluid">
            <div className="row">
               <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                   © {(new Date()).getFullYear()} CommentBox.io <Divider/> <Link to="/privacy">Privacy</Link> <Divider/> <Link to="/terms">Terms</Link>
               </div>
                <div className="center col-lg-4 col-md-4 hidden-sm hidden-xs">
                    Made with <span className="icon-free_breakfast" title="Time, care, and tea." />
                </div>
                <div className="right col-lg-4 col-md-4 col-sm-6 hidden-xs">
                    <a target="_blank" href="https://twitter.com/commentbox_io">
                        @commentbox_io
                    </a>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
