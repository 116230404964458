import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Link, StaticQuery, graphql } from 'gatsby';

import Footer from '../_global/Footer.jsx';
import './MainLayout.scss';

class DoNotTrack extends React.Component {

    state = {
        enabled: true
    };

    componentDidMount() {

        let enabled = navigator.doNotTrack == "yes" || navigator.doNotTrack == "1" || navigator.msDoNotTrack == "1";

        this.setState({ enabled });
    }

    render() {

        if (this.state.enabled) {
            return null;
        }

        return (
            <img
                src={`https://storage.googleapis.com/analytics.storage.commentbox.io/first-party-analytics.png?ref=${encodeURIComponent(document.referrer)}`}
                style={{ position: 'absolute', top: 0, left: 0, width: 1, height: 1 }}
            />
        );
    }
}

const MainLayout = ({ children }) => (
    <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (
            <>
                <Helmet
                    title={data.site.siteMetadata.title}
                >

                    <meta property="description" content={`Hosted commenting that's painless to embed, a pleasure to use, and a breeze to moderate.`} />
                    <meta property="keywords" content="comments, blogging, disqus" />

                    <meta property="og:url" content="https://commentbox.io" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="CommentBox.io - No ads. No Tracking. Just Comments." />
                    <meta property="og:description" content={`Hosted commenting that's painless to embed, a pleasure to use, and a breeze to moderate.`} />
                    <meta property="og:image" content="https://commentbox.io/shareable-facebook.png" />
                    <meta property="fb:app_id" content="246847302802803" />


                    <meta name="twitter:widgets:theme" content="light" />
                    <meta name="twitter:widgets:link-color" content="#398137" />
                    <meta name="twitter:widgets:border-color" content="#398137" />
                    <meta name="twitter:dnt" content="on" />
                    <meta name="twitter:widgets:csp" content="on" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:creator" content="@shaunpersad" />
                    <meta name="twitter:site" content="@shaunpersad" />
                    <meta name="twitter:title" content="CommentBox.io - No ads. No Tracking. Just Comments." />
                    <meta name="twitter:description" content={`Hosted commenting that's painless to embed, a pleasure to use, and a breeze to moderate.`} />
                    <meta name="twitter:image" content="https://commentbox.io/shareable-twitter.png" />

                    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                    <link rel="manifest" href="/manifest.json" />
                    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#398137" />
                    <meta name="msapplication-TileColor" content="#5f536c" />
                    <meta name="theme-color" content="#398137" />

                    <html lang="en" />
                </Helmet>
                <div className="main-layout">
                    <div className="main-layout-content">
                        {children}
                        <DoNotTrack />
                        <div className="push" />
                    </div>
                    <Footer />
                </div>
            </>
        )}
    />
);

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MainLayout;